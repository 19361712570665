.container-fluid
	max-width: $max-content
	display: flex
	flex-direction: column
	justify-content: space-between
	padding: 3rem 1.5rem
	min-height: 100vh
	height: auto
	z-index: 0

.header-svg,
.footer-svg
	fill: $blue
	position: absolute
	z-index: 0

.header-svg
	top: 0
	right: 0
	left: 0
	@media screen and (min-width: 1725px)
		left: inherit

.footer-svg
	bottom: 0
	left: 0
	transform: rotate(180deg)

h1,
h2,
h3,
h4,
h5,
h6
	@include transition
	color: $text-color
	font:
		family: 'Oswald', sans-serif
		weight: 300
    
h1
	font-size: 4rem
	margin-bottom: 1 * $spacer

	@include media-breakpoint-up(md)
		font-size: 5.4rem
		margin-bottom: 3 * $spacer

h2
	font-size: 3.4rem
	margin-bottom: 3 * $spacer

	@include media-breakpoint-up(md)
		font-size: 4.8rem
		margin-bottom: 6 * $spacer

.home-service
	margin-bottom: 2rem
	padding: 0

	@include media-breakpoint-up(md)
		margin-bottom: 4rem
		padding: 0 1.5rem

.service-title
	font-size: 2.4rem
	margin-bottom: 1rem

	@include media-breakpoint-up(md)
		font-size: 3rem
		margin-bottom: 2rem

p
	font:
		family: 'Roboto', sans-serif
		size: 1.8rem
		weight: 400
	line-height: 2
	line-height: 1.6
	color: $black

	&.intro
		margin-bottom: 3 * $spacer
		max-width: 50em

	.footer &
		color: $white

.btn
	font:
		family: 'Oswald'
		size: 2rem
		weight: 300

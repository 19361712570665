.header
	display: flex
	flex-direction: column
	justify-content: space-between
	z-index: 0
    
	@include media-breakpoint-up(xl)
		align-items: center
		flex-direction: row
		margin-bottom: 10rem


.navbar-brand
	z-index: 2

html
	font-size: 62.5%

body
	color: $text-color
	height: 100vh
	width: 100vw

.root
	background-color: $bg-color
	height: $root-dimmention
	min-height: $root-dimmention
	position: relative
	width: $root-dimmention

// Colors
$blue: rgba(51, 99, 151, 1)
$black: rgba(15, 15, 15, 1)
$white: rgba(255, 255, 255, 1)

$current-color: $black
$bg-color: $white
$theme-colors: ('primary': $blue)
$border-color: $blue
$text-color: $blue
$hover-color: darken($blue, 10%)

// Root container
$root-dimmention: 100%

$bordered-theme: false

// Max width of content
$max-content: 1530px

// Box shadow
$box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .15)

// Button
$btn-font-family: 'Oswald'
$input-btn-padding-y: 1rem
$input-btn-padding-x: 4.8rem
$btn-focus-width: 0

$grid-breackpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px )
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px )
